import fetch from "lib/fetch";
import { domain } from "lib/publicConf";

export default function swrFetch(pathname: string, graph: string) {
  const url = new URL(pathname, `${location.protocol}//${domain}`);

  if (graph !== undefined) {
    url.searchParams.set("graph", graph);
  }

  return fetch(url as unknown as RequestInfo);
}
