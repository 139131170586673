import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";
import Router from "next/router";
import { useEffect } from "react";
import useSWR from "swr";

import { UserWithMetadata } from "pages/api/user";

export default function useUser({
  graph = "organization,slackUser.teams",
  redirectTo = "",
  redirectIfFound = false,
} = {}) {
  const { data: user, mutate: mutateUser } = useSWR<UserWithMetadata>([
    "/api/user",
    graph,
  ]);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (redirectTo === "" || !user) {
      return;
    }

    if (user?.isLoggedIn) {
      Sentry.setUser({ email: user.email });
      Cookies.set("slug", user.organization.slug);
    } else {
      Sentry.setUser(null);
      Cookies.remove("slug");
    }

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo !== "" && !redirectIfFound && !user?.isLoggedIn) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && user?.isLoggedIn)
    ) {
      Router.push(redirectTo);
    }
  }, [user, redirectIfFound, redirectTo]);

  return { user, mutateUser };
}
