import FetchError from "lib/FetchError";

export default async function fetchJson<T>(
  resource: RequestInfo,
  init?: RequestInit | undefined,
) {
  try {
    const response = await fetch(resource, init);

    if (
      response.headers.get("content-type") === null ||
      response.headers.get("content-type")?.startsWith("application/json") ===
        false
    ) {
      throw new FetchError(resource, {
        init,
        response,
      });
    }

    // if the server replies, there's always some data in json
    // if there's a network error, it will throw at the previous line
    const data = (await response.json()) as T;

    // response.ok is true when res.status is 2xx
    // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
    if (response.ok) {
      return data;
    }

    throw new FetchError(resource, { init, response, data });
  } catch (error) {
    if (!(error instanceof FetchError)) {
      throw new FetchError(resource, { init, originalError: error as Error });
    } else {
      throw error;
    }
  }
}
