export default class FetchError<T> extends Error {
  data: T;
  response?: Response;
  originalError?: Error;
  init: RequestInit | undefined;
  resource: RequestInfo;
  constructor(
    resource: RequestInfo,
    {
      originalError,
      init,
      response = { status: 0 } as Response,
      data = {} as T,
    }: {
      originalError?: Error;
      init: RequestInit | undefined;
      response?: Response;
      data?: T;
    },
  ) {
    const message = `Error fetching ${resource}, status was ${response.status}`;
    super(message);
    this.data = data;
    this.response = response;
    this.originalError = originalError;
    this.init = init;
    this.resource = resource;
    this.name = "FetchError";
  }
}
