import * as Fathom from "fathom-client"; // * as Fathom is required for it to work
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { Router } from "next/router";
import "../css/global.css";
import "tailwindcss/tailwind.css";
import "almanak/style.css";
import { ReactElement, ReactNode, useEffect } from "react";
import { SWRConfig } from "swr";

import "nprogress/nprogress.css"; //styles of nprogress
import CrispChat from "components/CrispChat";
import log from "lib/log";
import { appEnv } from "lib/publicConf";
import swrFetch from "lib/swrFetch";

const faviconPrefixes = {
  development: "development-",
  preview: "preview-",
  production: "",
};

const faviconPrefix = faviconPrefixes[appEnv];

const TopProgressBar = dynamic(
  () => {
    return import("components/TopProgressBar");
  },
  { ssr: false },
);

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  err: Error;
};

Router.events.on("routeChangeComplete", (as, routeProps) => {
  if (!routeProps.shallow) {
    Fathom.trackPageview();
  }
});

function MyApp({ Component, pageProps, err }: AppPropsWithLayout) {
  // for dev: enforce https:
  if (typeof window !== "undefined") {
    if (location.hostname !== "localhost" && location.protocol !== "https:") {
      location.protocol = "https:";
    }
  }

  useEffect(() => {
    Fathom.load("TRNZWMSG", {
      includedDomains: ["turnshift.app"],
    });
  }, []);

  return (
    <SWRConfig
      value={{
        fetcher: swrFetch,
        onError: (swrError) => {
          log.error(swrError);
        },
      }}
    >
      <Head>
        <link
          href={`/${faviconPrefix}apple-touch-icon.png`}
          rel="apple-touch-icon"
          sizes="180x180"
        />
        <link href={`/${faviconPrefix}favicon.ico`} rel="icon" />
        <link
          href={`/${faviconPrefix}favicon-32x32.png`}
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link
          href={`/${faviconPrefix}favicon-16x16.png`}
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <link href={`/${faviconPrefix}site.webmanifest" rel="manifest`} />
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Inter.var.woff2?v=3.15"
          rel="preload"
          type="font/woff2"
        />
      </Head>
      <CrispChat crispWebsiteId="c027d1f9-0215-4cb1-893a-75d928264dd7" />
      <TopProgressBar />
      {Component.getLayout ? (
        Component.getLayout(<Component {...pageProps} err={err} />)
      ) : (
        <Component {...pageProps} />
      )}
    </SWRConfig>
  );
}

export default MyApp;
