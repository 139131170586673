export const domain =
  process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
    ? process.env.NEXT_PUBLIC_VERCEL_URL
    : process.env.NEXT_PUBLIC_DOMAIN;

export const algoliaApplicationId =
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID;

export const algoliaIndexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME;

export const appEnv = process.env.NEXT_PUBLIC_APP_ENV as
  | "development"
  | "preview"
  | "production";

export const stripeConfiguration = {
  publishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  prices: {
    monthly: {
      id: process.env.NEXT_PUBLIC_STRIPE_MONTHLY_PRICE_ID,
      price: parseInt(process.env.NEXT_PUBLIC_STRIPE_MONTHLY_PRICE, 10),
    },
    yearly: {
      id: process.env.NEXT_PUBLIC_STRIPE_YEARLY_PRICE_ID,
      price: parseInt(process.env.NEXT_PUBLIC_STRIPE_YEARLY_PRICE, 10),
    },
    [process.env.NEXT_PUBLIC_STRIPE_MONTHLY_PRICE_ID]: {
      price: parseInt(process.env.NEXT_PUBLIC_STRIPE_MONTHLY_PRICE, 10),
      monthlyPrice: parseInt(process.env.NEXT_PUBLIC_STRIPE_MONTHLY_PRICE, 10),
      name: "monthly",
    },
    [process.env.NEXT_PUBLIC_STRIPE_YEARLY_PRICE_ID]: {
      price: parseInt(process.env.NEXT_PUBLIC_STRIPE_YEARLY_PRICE, 10),
      monthlyPrice:
        parseInt(process.env.NEXT_PUBLIC_STRIPE_YEARLY_PRICE, 10) / 12,
      name: "yearly",
    },
  },
};

export const featureFlags = {};

export const slack = {
  appScopes: {
    public: [
      "chat:write",
      "users:read",
      "users:read.email",
      "channels:read",
      "channels:join",
      "channels:manage",
      "team:read",
      "chat:write.public",
    ].sort(),
    private: [
      "chat:write", // send messages start/end
      "users:read", // list users
      "users:read.email", // access their emails
      "channels:read", // list public channels and get their current topic
      "channels:join", // join public channels
      "channels:manage", // change public / private topic
      "team:read", // read Slack team info
      "groups:read", // list private channels and get their current topic
      "groups:write", // send messages start/end
      "chat:write.public", // allows sending messages to public channels even without joining them
    ].sort(),
  },
  appId: process.env.NEXT_PUBLIC_SLACK_APP_ID,
};

export const gitCommitSha = process.env.NEXT_PUBLIC_GIT_COMMIT_SHA;

export const googlePlacesApiKey = process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY;
