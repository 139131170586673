// @ts-nocheck Not interested in this file
/* eslint-disable no-console */

const logLevels = {
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4,
};

// Determine the current log level based on environment variables or use default
const currentLogLevel = logLevels[process.env.LOG_LEVEL] || logLevels.INFO;

export default {
  info(...args) {
    if (currentLogLevel <= logLevels.INFO) {
      console.log(...args);
    }
  },

  debug(...args) {
    if (currentLogLevel <= logLevels.DEBUG) {
      console.debug(...args);
    }
  },

  error(...args) {
    if (currentLogLevel <= logLevels.ERROR) {
      console.error(...args);
    }
  },

  warn(...args) {
    if (currentLogLevel <= logLevels.WARN) {
      console.warn(...args);
    }
  },
};
