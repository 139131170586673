import { useRouter } from "next/router";
import { useEffect, memo } from "react";

import useScript from "lib/hooks/useScript";
import useUser from "lib/hooks/useUser";
import { appEnv } from "lib/publicConf";

export default memo(CrispChat);

declare global {
  interface Window {
    $crisp: Array<unknown>;
    CRISP_WEBSITE_ID: string;
    CRISP_TOKEN_ID?: string;
    CRISP_RUNTIME_CONFIG: {
      session_merge: boolean;
    };
  }
}

function CrispChat({
  crispWebsiteId,
}: {
  crispWebsiteId: string;
}): JSX.Element | null {
  const router = useRouter();

  useEffect(() => {
    window.$crisp = [];
    window.$crisp.push(["safe", true]);
    window.CRISP_WEBSITE_ID = crispWebsiteId;
    window.CRISP_RUNTIME_CONFIG = {
      session_merge: true,
    };
  }, [crispWebsiteId]);

  useScript("https://client.crisp.chat/l.js");

  const { user } = useUser();

  useEffect(() => {
    if (user?.isLoggedIn === undefined || user?.isLoggedIn === false) {
      return;
    }

    // if the user is admin, we do not set any token or variables
    // so that we never mixes users when personifying (Crisp best practice)
    // admin will be true when personifying too
    if (user.personifying === true) {
      delete window.CRISP_TOKEN_ID;
      window.$crisp.push(["do", "session:reset"]);
      window.$crisp.push(["safe", true]);
      window.$crisp.push(["set", "user:email", ["support@turnshift.app"]]);
      window.$crisp.push([
        "set",
        "user:avatar",
        [
          "https://www.gravatar.com/avatar/9de4714cbfb46bd7d30696d3b18ef9d0?s=256",
        ],
      ]);
      window.$crisp.push(["set", "user:company", ["TurnShift"]]);
      window.$crisp.push(["set", "user:nickname", [`${appEnv} admin`]]);
      return;
    }

    if (user?.isLoggedIn === true) {
      window.CRISP_TOKEN_ID = user.privateUuid;
      window.$crisp.push(["do", "session:reset"]);
      window.$crisp.push(["safe", true]);
      window.$crisp.push(["set", "user:email", [user.email]]);
      window.$crisp.push(["set", "user:nickname", [user.name]]);
      window.$crisp.push(["set", "user:avatar", [user.slackUser.avatarHdUrl]]);
      window.$crisp.push(["set", "user:company", [user.organization.name]]);
    }
  }, [user]);

  useEffect(() => {
    if (
      router.pathname ===
      "/app/[organizationSlug]/calendar/monthly/[year]/[month]/[[...scheduleIds]]"
    ) {
      window.$crisp.push(["do", "chat:hide"]);
      window.$crisp.push([
        "on",
        "message:received",
        () => {
          window.$crisp.push(["do", "chat:show"]);
          window.$crisp.push(["do", "chat:open"]);
        },
      ]);
      window.$crisp.push([
        "on",
        "chat:closed",
        () => {
          window.$crisp.push(["do", "chat:hide"]);
        },
      ]);
    } else {
      window.$crisp.push(["off", "message:received"]);
      window.$crisp.push(["do", "chat:show"]);
    }
  }, [router.pathname]);

  return null;
}
